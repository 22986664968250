export const getInitialState = () => ({
	options: {},
	fieldMappingsOptions: {},
	segmentFieldMappingsOptions: {
		sourceField: {},
		destinationField: {}
	},
	connection: null,
	sourceSegment: null,
	destinationSegment: null,
	engagementEventSegment: null,
	segmentGroups: [],
	destinationSegments: [],
	generalErrors: [],
	contactSyncRules: [],
	contactCreationRuleOptions: [],
	contactCreationRules: [],
	fieldMappings: [],
	reversedFieldMappings: [],
	unifiedFieldMappings: [],
	engagementSyncRules: [],
	engagementSyncRuleOptions: {},
	view: {
		contactSync: {
			contactSyncTab: false,
			contactSyncRules: false,
			contactCreation: false,
			mapFields: false
		},
		engagementSync: {
			engagementSyncTab: false,
			formEngagementSync: {
				formEngagementSyncComponent: false,
				recordEngagement: false,
				identifyLeads: false,
				preDealCreationSetting: false
			},
			emailEngagementSync: {
				emailEngagementSyncComponent: false,
				emailEngagementSyncLimitTab: false
			},
			dealEngagementSync: {
				dealEngagementSyncComponent: false
			}
		}
	},
	feed: [],
	feedPagination: {
		currentPage: 1,
		pageCount: 1
	},
	stats: {
		counts: {}
	},
	connectionStatusOptions: [],
	period: null,
	isEngagementSyncUpgradeDialogOpen: false,
	isMapRequiredFieldsDialogOpen: false,
	isDialogRecentEngagementsOpen: false,
	isRefreshFieldsInProgress: false
});
